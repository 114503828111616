//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { i18n } from '@/vueI18n';
import { UserModel } from '@/modules/auth/user-model';
import { mapGetters } from 'vuex';
import Roles from '@/security/roles';
const { fields } = UserModel;
import i18n from '@/vueI18n'
import moment from 'moment'
export default {
  name: 'app-notifications-list',

  data() {
    return {
      selectedId: '',
      record: null,
      dialogVisible: false,
    };
  },

  // mounted hook call two function when page loading first is doFilter to diplay table upon to that filteration
  //

  //   mounted() {
  //     // console.log('tour guide')
  //     this.doFilter();
  //     this.doMountTable(this.$refs.table);
  //   },

  computed: {
    ...mapGetters({
      //   rows: 'tourGuide/list/rows',
      //   count: 'tourGuide/list/count',
      //   loading: 'tourGuide/list/loading',
      //   pagination: 'tourGuide/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
      notifications:'home/notifications'
    }),

    language(){
      return i18n.locale
    },

    fields() {
      return fields;
    },
  },
  created() {
    // console.log(this.rows);
  },

  methods: {
    // ...mapActions({
    //   doChangeSort: 'tourGuide/list/doChangeSort',
    //   doChangePaginationCurrentPage:
    //     'tourGuide/list/doChangePaginationCurrentPage',
    //   doChangePaginationPageSize:
    //     'tourGuide/list/doChangePaginationPageSize',
    //   doMountTable: 'tourGuide/list/doMountTable',
    //   doFetch: 'tourGuide/list/doFetch',
    // }),
    // mapAction call to this functions from store { doFetch && doChangeSort && doChangePaginationCurrentPage && doChangePaginationPageSize && doMountTable }

    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },
    presenterDate(date){
      if (this.lang == 'ar') {
              return date
                ? moment.unix(date.seconds)
                    .locale('ar')
                    .format('D MMM, YYYY')
                : 'ـــــــ';
            }
            return date
              ? moment.unix(date.seconds)
                  .locale('en')
                  .format('D MMM, YYYY')
              : 'ـــــــ';
    },

    hasNewNotifications(notifications) {
      if (
        notifications.some(
          (notification) => notification.isNew === true,
        )
      ) {
        return true;
      }
      return false;
    },
    getNewNotifications(notifications) {
      var newNotifications = notifications.filter(
        (notification) => {
          return notification.isNew === true;
        },
      );
      return newNotifications;
    },

    getOldNotifications(notifications) {
      var oldNotifications = notifications.filter(
        (notification) => {
          return notification.isNew === false;
        },
      );
      return oldNotifications;
    },

    // presenter function display data from model to fields

    presenter(row, fieldName) {
      return UserModel.presenter(row, fieldName);
    },

    // doFilter function first check validation then clear it finally call doFetch and pass filter to it

    async doFilter() {
      // try {
      //   await this.$refs.form.validate();
      //   this.$refs.form.clearValidate();
      // } catch (error) {
      //   return;
      // }
      const filter = {};
      return this.doFetch({
        filter,
      });
    },

    // i18n for localization

    // i18n(code) {
    //   return i18n(code);
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
